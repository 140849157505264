import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-automation-icon',
    standalone: true,
    imports: [CommonModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `<svg
        fill="currentColor"
        height="100%"
        viewBox="0 0 18 19"
        width="100%"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.77769 18.2414H5.62138C6.32023 18.2414 6.87894 17.6827 6.87894 16.9838L6.87985 14.0719C6.87985 13.3731 6.32114 12.8144 5.62229 12.8144H3.94523V11.5795C3.94523 11.3002 4.29465 11.0436 4.69049 11.0436H13.217C13.6365 11.0436 13.9622 11.2765 13.9622 11.5795V12.8144H12.378C11.6791 12.8144 11.1204 13.3731 11.1204 14.0719V16.9838C11.1204 17.6827 11.6791 18.2414 12.378 18.2414H16.2217C16.9205 18.2414 17.4792 17.6827 17.4792 16.9838V14.0719C17.4792 13.3731 16.9205 12.8144 16.2217 12.8144H14.6374V11.5795C14.6374 10.9043 13.985 10.3447 13.1933 10.3447H9.32584V8.71404C11.2595 8.55116 12.7738 6.94324 12.7738 4.98682C12.7738 2.91393 11.0967 1.23584 9.02277 1.23584C6.94988 1.23584 5.27179 2.9129 5.27179 4.98682C5.27179 6.9205 6.73957 8.50485 8.62582 8.71404V10.3684L4.71294 10.3675C3.92128 10.3675 3.26883 10.9262 3.26883 11.6023V12.8371H1.77832C1.07946 12.8371 0.520753 13.3958 0.520753 14.0947V17.0066C0.519843 17.6827 1.07855 18.2414 1.77741 18.2414L1.77769 18.2414Z" />
    </svg> `,
})
export class AutomationIconComponent {}
